import React, { useMemo } from 'react';
import AggregationCardContext from 'components/Product/Context/AggregationCardContext';
import useProductSlicesCombiner from 'components/Product/Redux/Slices/useProductSlicesCombiner';
import AggregationContent from './AggregationContent/AggregationContent';

const AggregationCard = () => {
  const { productAggregations, prodSources } = useProductSlicesCombiner();

  const productionNames = useMemo(
    () => (prodSources as any[]).map((source) => source.name),
    [prodSources]
  );

  const productionHistory = useMemo(
    () =>
      (productAggregations as any[]).filter(({ source }) =>
        productionNames.includes(source)
      ),
    [productAggregations, productionNames]
  );

  const dataHistory = useMemo(
    () =>
      (productAggregations as any[]).filter(
        ({ source }) => !productionNames.includes(source)
      ),
    [productAggregations, productionNames]
  );

  if (!productionHistory.length && !dataHistory.length) return <></>;

  return (
    <>
      {/* Production History Table */}
      {productionHistory.length > 0 && (
        <AggregationCardContext.Provider
          value={{ title: 'Production history', data: productionHistory }}
        >
          <AggregationContent />
        </AggregationCardContext.Provider>
      )}

      {/* Data History Table */}
      {dataHistory.length > 0 && (
        <AggregationCardContext.Provider
          value={{ title: 'Data history', data: dataHistory }}
        >
          <AggregationContent />
        </AggregationCardContext.Provider>
      )}
    </>
  );
};

export default AggregationCard;
