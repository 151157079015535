import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { AppDispatch } from 'redux/store';
import { getProductAggregationsStart } from 'actions';
import useProductSlicesCombiner from '../Slices/useProductSlicesCombiner';
import usePagination from './usePagination';

export const useProductAggregations = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { assetId } = useParams();
  const { isAuthenticated, currentProduct } = useProductSlicesCombiner();
  const { page, pageSize, handlePageChange, handlePageSizeChange } =
    usePagination();

  const fetchData = useCallback(async () => {
    await dispatch(getProductAggregationsStart(assetId, page, pageSize));
  }, [assetId, page, pageSize]);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/');
    } else {
      fetchData();
    }
  }, [isAuthenticated, currentProduct, fetchData, pageSize]);

  return { handlePageChange, handlePageSizeChange, page, pageSize };
};
