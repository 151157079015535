import React, { useCallback, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { LinearProgress } from '@mui/material';
import { DataGridPro as Table } from '@mui/x-data-grid-pro';
import {
  setAssetsMeteringToDisplayInDialog,
  displayAggregationTransactionsModal,
  setOpenAggregationDetails,
  getProductAggregationsStart,
} from 'actions';
import LoadAll from 'components/commons/LoadAll/LoadAll';
import AggregationCardContext from 'components/Product/Context/AggregationCardContext';
import GridToolbar from 'components/Product/CustomComponents/Toolbar/AggregationToolbar/CustomTableToolbar/GridToolbar';
import useProductSlicesCombiner from 'components/Product/Redux/Slices/useProductSlicesCombiner';
import {
  FILTER_MODE_CLIENT_SIDE,
  PAGINATION_MODE_SERVER_SIDE,
} from 'components/Transactions/utils/constants';
import { AppDispatch } from 'redux/store';
import { getColumns } from './utils/columns';
import { sortAssetsMeteringAggregations } from './utils/utils';
import usePagination from 'components/Product/Redux/CustomHooks/usePagination';
import { useNavigate, useParams } from 'react-router';

const AggregationTable = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const { isAuthenticated } = useProductSlicesCombiner();
  const { page, pageSize, handlePageChange, handlePageSizeChange } =
    usePagination();
  const { assetId } = useParams();

  const { data, title } = useContext(AggregationCardContext);

  const cleanedTitle = (title as string).replace(/History/g, '').trim();

  const {
    loadingProductAggregations,
    productAggregations,
    totalCountAggregations,
  } = useProductSlicesCombiner();

  const handleRowClick = (rowData: any, event: any) => {
    if (event.ignore) return true;
    const selectedAggregation = rowData.row;
    const assetsMeteringToDisplayInDialogSorted =
      sortAssetsMeteringAggregations(selectedAggregation);
    dispatch(setOpenAggregationDetails(true));
    dispatch(
      setAssetsMeteringToDisplayInDialog(assetsMeteringToDisplayInDialogSorted)
    );
  };

  const handleOpenTransactionDialog = (aggregationId: string) => {
    dispatch(displayAggregationTransactionsModal(aggregationId));
  };

  const fetchAggregationsData = useCallback(async () => {
    await dispatch(getProductAggregationsStart(assetId, page, pageSize));
  }, [assetId, page, pageSize]);

  useEffect(() => {
    if (pageSize > 100) {
      if (!isAuthenticated) {
        navigate('/');
      } else {
        fetchAggregationsData();
      }
    }
  }, [pageSize]);

  return (
    <Table
      loading={loadingProductAggregations as boolean}
      pagination
      paginationMode={PAGINATION_MODE_SERVER_SIDE}
      filterMode={FILTER_MODE_CLIENT_SIDE}
      columns={getColumns(handleOpenTransactionDialog)}
      onRowClick={handleRowClick}
      rows={data}
      components={{
        LoadingOverlay: LinearProgress,
        Toolbar: GridToolbar,
        Footer: () => (
          <LoadAll
            onClick={() =>
              handlePageSizeChange(totalCountAggregations as number)
            }
            loadedRows={(productAggregations as any[]).length}
            totalRows={totalCountAggregations as number}
            typeOfData={`${cleanedTitle} aggregations`}
          />
        ),
      }}
      page={page}
      pageSize={pageSize}
      rowCount={totalCountAggregations}
      onPageChange={handlePageChange}
      onPageSizeChange={handlePageSizeChange}
    />
  );
};

export default AggregationTable;
