/* eslint-disable */

import { EnumAdministrationDialogIdentifiers } from 'components/Administration/Generic/Form/CustomForms/TransferRetire/utils/enums';
import moment from 'moment';
import { constants } from '../constants/index';
import { enums } from '../enums';

const INITIAL_STATE = {
  showDateFilter: false,
  loadingSetMissingIssuerOnTransactions: false,
  loadingUpdateBalancesCache: false,
  loadingIncreaseBalance: false,
  loadingDecreaseBalance: false,
  loadingIssueCertificate: false,
  loadingIssueCertificatesBulk: false,
  loadingNotificationFutureIssue: false,
  loadingCheckAttributes: false,
  loadingList: false,
  loadingLog: false,
  loadingLogList: false,
  loadingAdministrationAction: false,
  loading: false,
  loadingBalance: false,
  selectedOrganizationBalanceAccount: null,
  actionDialogOpen: false,
  formToDisplayInActionDialog: false,
  increaseBalanceFormOpen: false,
  decreaseBalanceFormOpen: false,
  issueCertificateFormOpen: false,
  displayIncreaseBalanceFormInDialog: true,
  displayDecreaseBalanceFormInDialog: true,
  displayIssueCertificateFormInDialog: true,
  displayIssueCertificateBulkFormInDialog: true,
  displayNotificationIssueFormInDialog: true,
  displayCheckAttributesFormInDialog: true,
  displayAllFeaturesValue: false,
  displaySingleOriginDataUploader: false,
  displayMultipleOriginDataUploader: false,
  logList: [],
  startDate: moment().subtract(1, 'days').toDate(),
  endDate: moment().subtract(1, 'days').toDate(),
  dialogStartDateOpen: false,
  dialogEndDateOpen: false,
  dialogWarningOpen: false,
  organizationId: null,
  actionId: enums.AdministrationActionTypes.MATCHING_LIVE,
  localDataset: null,
  localConfirmationDataset: null,
  localBulkOperationsDataset: null,
  localConfirmationSourceDocument: null,
  localSourceDocument: null,
  minVolumeToUpload: null,
  alreadyUploadedVolume: null,
  loadingDataset: false,
  loadingConfirmationDataset: false,
  loadingBulkOperationsDataset: false,
  loadingIssuanceList: false,
  comment: '',
  scheduledTasksMonitoring: [],
  increaseBalanceData: {
    _for: undefined,
    _origin: undefined,
    _volume: 0,
    _year: undefined,
    _month: undefined,
    _comment: '',
    _txHash: '',
  },
  decreaseBalanceData: {
    _for: undefined,
    _origin: undefined,
    _volume: 0,
    _year: undefined,
    _month: undefined,
    _comment: '',
    _txHash: '',
  },
  issueCertificateData: {
    _origin: undefined,
    _year: undefined,
    _month: undefined,
    _volume: 0,
  },
  issueCertificatesBulkData: {
    _organization: undefined,
    _year: moment().format('YYYY'),
    _month: moment().format('MM'),
  },
  notifyFutureIssuanceData: {
    _organization: undefined,
    _year: undefined,
    _month: undefined,
  },
  notificationFutureIssuanceList: [
    { product: undefined, production: 0, checked: false },
  ],
  bulkIssuanceList: [{ product: undefined, production: 0, checked: false }],
  numSelected: 0,
  totalIssueBulkProductsCount: 0,
  checkAttributesData: {
    _origin: undefined,
  },
  increaseBalanceFormError: undefined,
  decreaseBalanceFormError: undefined,
  issueCertificatesFormError: undefined,
  issueCertificatesBulkFormError: undefined,
  notifyFutureIssuanceFormError: undefined,
  checkAttributesFormError: undefined,
  checkAttributesResult: [],
  loadingMaxQuantityForProduct: false,
  maxQuantityForProduct: null,
  displayCheckAttributesResult: false,
  resetDecreaseBalanceForm: false,
  resetIncreaseBalanceForm: false,
  resetIssuanceNotificationEmailForm: false,
  resetIssueCertificateForm: false,
  resetIssueCertificateInBulkForm: false,
  resetCheckAttributesForm: false,
};

const administrationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'OPEN_INCREASE_BALANCE_FORM':
      return {
        ...state,
        increaseBalanceFormOpen: true,
        displayIncreaseBalanceFormInDialog: true,
        increaseBalanceFormError: undefined,
      };

    case 'OPEN_ACTION_DIALOG':
      return {
        ...state,
        actionDialogOpen: true,
        formToDisplayInActionDialog: action.payload,
      };
    case 'CLOSE_ACTION_DIALOG':
      let newState = { ...state, actionDialogOpen: false };

      if (
        action.payload.dialogIdentifier ==
        EnumAdministrationDialogIdentifiers.BULK_OPERATION
      ) {
        newState = {
          ...newState,
          localBulkOperationsDataset: null,
          loadingBulkOperationsDataset: false,
        };
      }
      return newState;

    case 'OPEN_ISSUE_CERTIFICATES_FORM':
      return {
        ...state,
        issueCertificateFormOpen: true,
        displayIssueCertificateFormInDialog: true,
        issueCertificatesFormError: undefined,
      };

    case 'CLOSE_INCREASE_BALANCE_FORM':
      return {
        ...state,
        increaseBalanceFormOpen: false,
        displayIncreaseBalanceFormInDialog: true,
        actionDialogOpen: false,
      };
    case 'CLOSE_DECREASE_BALANCE_FORM':
      return {
        ...state,
        decreaseBalanceFormOpen: false,
        displayDecreaseBalanceFormInDialog: true,
        actionDialogOpen: false,
      };
    case 'CLOSE_ISSUE_CERTIFICATES_FORM':
      return {
        ...state,
        issueCertificateFormOpen: false,
        displayIssueCertificateFormInDialog: true,
        actionDialogOpen: false,
      };
    case 'CLOSE_NOTIFICATION_OF_ISSUANCE_FORM':
      return {
        ...state,
        displayNotificationIssueFormInDialog: true,
        actionDialogOpen: false,
      };
    case 'CLOSE_ISSUE_CERTIFICATES_BULK_FORM':
      return {
        ...state,
        displayIssueCertificateBulkFormInDialog: true,
        actionDialogOpen: false,
      };
    case 'CLOSE_CHECK_ATTRIBUTES_FORM':
      return {
        ...state,
        displayCheckAttributesFormInDialog: true,
        actionDialogOpen: false,
      };
    case 'CLOSE_JSON_DIFF_CHECK_ATTRIBUTES_FORM':
      return {
        ...state,
        actionJSONDiffDialogOpen: false,
      };

    case 'DISPLAY_FORM_INCREASE_BALANCE':
      return { ...state, displayIncreaseBalanceFormInDialog: true };
    case 'DISPLAY_FORM_DECREASE_BALANCE':
      return { ...state, displayDecreaseBalanceFormInDialog: true };
    case 'DISPLAY_FORM_ISSUE_CERTIFICATES':
      return { ...state, displayIssueCertificateFormInDialog: true };
    case 'DISPLAY_NOTIFICATION_OF_ISSUANCE_FORM':
      return { ...state, displayNotificationIssueFormInDialog: true };
    case 'DISPLAY_FORM_ISSUE_CERTIFICATES_BULK':
      return { ...state, displayIssueCertificateBulkFormInDialog: true };
    case 'DISPLAY_FORM_CHECK_ATTRIBUTES':
      return { ...state, displayCheckAttributesFormInDialog: true };

    case 'INCREASE_FOR_CHANGED':
      return {
        ...state,
        increaseBalanceData: {
          ...state.increaseBalanceData,
          _for: action.payload,
        },
      };
    case 'DECREASE_FOR_CHANGED':
      return {
        ...state,
        decreaseBalanceData: {
          ...state.decreaseBalanceData,
          _for: action.payload,
        },
      };
    case 'ISSUE_ORIGIN_CHANGED':
      return {
        ...state,
        issueCertificateData: {
          ...state.issueCertificateData,
          _origin: action.payload,
        },
      };

    case 'INCREASE_ORIGIN_CHANGED':
      return {
        ...state,
        increaseBalanceData: {
          ...state.increaseBalanceData,
          _origin: action.payload,
        },
      };
    case 'DECREASE_ORIGIN_CHANGED':
      return {
        ...state,
        decreaseBalanceData: {
          ...state.decreaseBalanceData,
          _origin: action.payload,
        },
      };

    case 'INCREASE_VOLUME_CHANGED':
      return {
        ...state,
        increaseBalanceData: {
          ...state.increaseBalanceData,
          _volume: action.payload,
        },
      };
    case 'DECREASE_VOLUME_CHANGED':
      return {
        ...state,
        decreaseBalanceData: {
          ...state.decreaseBalanceData,
          _volume: action.payload,
        },
      };
    case 'ISSUE_VOLUME_CHANGED':
      return {
        ...state,
        issueCertificateData: {
          ...state.issueCertificateData,
          _volume: action.payload,
        },
      };

    case 'INCREASE_YEAR_CHANGED':
      return {
        ...state,
        increaseBalanceData: {
          ...state.increaseBalanceData,
          _year: action.payload,
        },
      };
    case 'DECREASE_YEAR_CHANGED':
      return {
        ...state,
        decreaseBalanceData: {
          ...state.decreaseBalanceData,
          _year: action.payload,
        },
      };
    case 'ISSUE_YEAR_CHANGED':
      return {
        ...state,
        issueCertificateData: {
          ...state.issueCertificateData,
          _year: action.payload,
        },
      };

    case 'INCREASE_MONTH_CHANGED':
      return {
        ...state,
        increaseBalanceData: {
          ...state.increaseBalanceData,
          _month: action.payload,
        },
      };
    case 'DECREASE_MONTH_CHANGED':
      return {
        ...state,
        decreaseBalanceData: {
          ...state.decreaseBalanceData,
          _month: action.payload,
        },
      };
    case 'ISSUE_MONTH_CHANGED':
      return {
        ...state,
        issueCertificateData: {
          ...state.issueCertificateData,
          _month: action.payload,
        },
      };

    case 'TOGGLE_DISPLAY_ALL_FEATURES':
      return {
        ...state,
        displayAllFeaturesValue: !state.displayAllFeaturesValue,
      };

    case 'SHOW_MULTIPLE_ORIGIN_DATA_UPLOADER':
      return {
        ...state,
        displayMultipleOriginDataUploader: true,
      };

    case 'HIDE_MULTIPLE_ORIGIN_DATA_UPLOADER':
      return {
        ...state,
        displayMultipleOriginDataUploader: false,
      };
    case 'TOGGLE_DISPLAY_MULTIPLE_ORIGIN_DATA_UPLOADER':
      return {
        ...state,
        displayMultipleOriginDataUploader:
          !state.displayMultipleOriginDataUploader,
      };

    case 'SHOW_SINGLE_ORIGIN_DATA_UPLOADER':
      return {
        ...state,
        displaySingleOriginDataUploader: true,
      };

    case 'HIDE_SINGLE_ORIGIN_DATA_UPLOADER':
      return {
        ...state,
        displaySingleOriginDataUploader: false,
      };
    case 'TOGGLE_DISPLAY_SINGLE_ORIGIN_DATA_UPLOADER':
      return {
        ...state,
        displaySingleOriginDataUploader: !state.displaySingleOriginDataUploader,
      };

    case 'INCREASE_TX_HASH_CHANGED':
      return {
        ...state,
        increaseBalanceData: {
          ...state.increaseBalanceData,
          _txHash: action.payload,
        },
      };
    case 'DECREASE_TX_HASH_CHANGED':
      return {
        ...state,
        decreaseBalanceData: {
          ...state.decreaseBalanceData,
          _txHash: action.payload,
        },
      };
    case 'INCREASE_COMMENT_CHANGED':
      return {
        ...state,
        increaseBalanceData: {
          ...state.increaseBalanceData,
          _comment: action.payload,
        },
      };
    case 'DECREASE_COMMENT_CHANGED':
      return {
        ...state,
        decreaseBalanceData: {
          ...state.decreaseBalanceData,
          _comment: action.payload,
        },
      };

    case 'DISPLAY_WARNING_INCREASE_BALANCE':
      return {
        ...state,
        displayIncreaseBalanceFormInDialog: false,
        increaseBalanceData: {
          ...state.increaseBalanceData,
          _origin: action.payload.origin,
          _for: action.payload.organization,
          _volume: action.payload.volume,
          _year: action.payload.year,
          _month: action.payload.month,
          _txHash: action.payload.txHash,
          _comment: action.payload.comment,
        },
      };
    case 'DISPLAY_WARNING_DECREASE_BALANCE':
      return {
        ...state,
        displayDecreaseBalanceFormInDialog: false,
        decreaseBalanceData: {
          ...state.increaseBalanceData,
          _origin: action.payload.origin,
          _for: action.payload.organization,
          _volume: action.payload.volume,
          _year: action.payload.year,
          _month: action.payload.month,
          _txHash: action.payload.txHash,
          _comment: action.payload.comment,
        },
      };
    case 'DISPLAY_WARNING_ISSUE_CERTIFICATES':
      return {
        ...state,
        displayIssueCertificateFormInDialog: false,
        issueCertificateData: {
          ...state.issueCertificateData,
          _origin: action.payload.origin,
          _year: action.payload.year,
          _month: action.payload.month,
          _volume: action.payload.volume,
        },
      };

    case 'DISPLAY_WARNING_ISSUE_CERTIFICATES_BULK':
      return {
        ...state,
        displayIssueCertificateBulkFormInDialog: false,
        issueCertificatesBulkData: {
          ...state.issueCertificatesBulkData,
          _organization: action.payload.organization,
          _year: action.payload.year,
          _month: action.payload.month,
        },
      };
    case 'LOADING_ISSUANCE_LIST':
      return {
        ...state,
        loadingIssuanceList: action.payload,
      };
    case 'DISPLAY_WARNING_NOTIFICATION_OF_ISSUANCE':
      return {
        ...state,
        displayNotificationIssueFormInDialog: false,
        notifyFutureIssuanceData: {
          ...state.notifyFutureIssuanceData,
          _organization: action.payload.organization,
          _year: action.payload.year,
          _month: action.payload.month,
        },
      };
    case 'SET_BULK_ISSUANCE_LIST':
      return {
        ...state,
        bulkIssuanceList: action.payload,
        numSelected: [...action.payload].filter((product) => product.checked)
          .length,
        totalIssueBulkProductsCount: action.payload.length,
      };

    case 'SET_NOTIFICATION_FUTURE_ISSUANCE_LIST':
      return {
        ...state,
        notificationFutureIssuanceList: action.payload,
        numSelected: action.payload.length,
      };

    case 'DISPLAY_WARNING_CHECK_ATTRIBUTES':
      return {
        ...state,
        displayCheckAttributesFormInDialog: false,
        loadingCheckAttributes: true,
        checkAttributesData: {
          ...state.checkAttributesData,
          _origin: action.payload,
        },
      };

    case 'SET_ISSUER_ON_TRANSACTIONS_START':
      return { ...state, loadingSetMissingIssuerOnTransactions: true };
    case 'SET_ISSUER_ON_TRANSACTIONS_SUCCESS':
      return { ...state, loadingSetMissingIssuerOnTransactions: false };
    case 'SET_ISSUER_ON_TRANSACTIONS_FAILS':
      return { ...state, loadingSetMissingIssuerOnTransactions: false };
    case 'UPDATE_BALANCES_CACHE_START':
      return { ...state, loadingUpdateBalancesCache: true };
    case 'UPDATE_BALANCES_CACHE_SUCCESS':
      return { ...state, loadingUpdateBalancesCache: false };
    case 'UPDATE_BALANCES_CACHE_FAILS':
      return { ...state, loadingUpdateBalancesCache: false };

    case 'INCREASE_BALANCE_START':
      return { ...state, loadingIncreaseBalance: true };
    case 'DECREASE_BALANCE_START':
      return { ...state, loadingDecreaseBalance: true };
    case 'ISSUE_CERTIFICATES_START':
      return { ...state, loadingIssueCertificate: true };
    case 'ISSUE_CERTIFICATES_BULK_START':
      return { ...state, loadingIssueCertificatesBulk: true };
    case 'NOTIFICATION_OF_ISSUANCE_START':
      return { ...state, loadingNotificationFutureIssue: true };
    case 'CHECK_ATTRIBUTES_START':
      return { ...state, loadingCheckAttributes: true };

    case 'INCREASE_BALANCE_SUCCESS':
      return { ...state, loadingIncreaseBalance: false };
    case 'DECREASE_BALANCE_SUCCESS':
      return { ...state, loadingDecreaseBalance: false };
    case 'ISSUE_CERTIFICATES_SUCCESS':
      return { ...state, loadingIssueCertificate: false };
    case 'ISSUE_CERTIFICATES_BULK_SUCCESS':
      return { ...state, loadingIssueCertificatesBulk: false };
    case 'NOTIFICATION_OF_ISSUANCE_SUCCESS':
      return { ...state, loadingNotificationFutureIssue: false };
    case 'CHECK_ATTRIBUTES_SUCCESS':
      return { ...state, loadingCheckAttributes: false };

    case 'SET_CHECK_ATTRIBUTES_RESULT':
      return {
        ...state,
        loadingCheckAttributes: false,
        displayCheckAttributesResult: true,
        displayCheckAttributesFormInDialog: false,
        checkAttributesResult: action.payload,
      };
    case 'RESET_CHECK_ATTRIBUTES_RESULT':
      return {
        ...state,
        displayCheckAttributesResult: false,
        checkAttributesResult: [],
      };

    case 'INCREASE_BALANCE_FAIL':
      return { ...state, loadingIncreaseBalance: false };
    case 'DECREASE_BALANCE_FAIL':
      return { ...state, loadingDecreaseBalance: false };
    case 'ISSUE_CERTIFICATES_FAIL':
      return { ...state, loadingIssueCertificate: false };
    case 'ISSUE_CERTIFICATES_BULK_FAIL':
      return { ...state, loadingIssueCertificatesBulk: false };
    case 'NOTIFICATION_OF_ISSUANCE_FAIL':
      return { ...state, loadingNotificationFutureIssue: false };
    case 'CHECK_ATTRIBUTES_FAIL':
      return { ...state, loadingCheckAttributes: false };

    case 'DATASET_CHANGED':
      return {
        ...state,
        localDataset: action.payload,
      };

    case 'CONFIRMATION_SOURCE_DOCUMENT_CHANGED':
      return {
        ...state,
        localConfirmationSourceDocument: action.payload,
      };

    case 'SET_ALREADY_UPLOADED_VOLUME':
      return {
        ...state,
        alreadyUploadedVolume: action.payload,
      };
    case 'SET_MINIMUM_VOLUME_TO_UPLOAD':
      return {
        ...state,
        minVolumeToUpload: action.payload,
      };

    case 'SOURCE_DOCUMENT_CHANGED':
      return {
        ...state,
        localSourceDocument: action.payload,
      };

    case 'CONFIRMATION_DATASET_CHANGED':
      return {
        ...state,
        localConfirmationDataset: action.payload,
      };

    case 'BULK_OPERATIONS__DATASET_CHANGED':
      return {
        ...state,
        localBulkOperationsDataset: action.payload,
      };

    case 'UPLOAD_CONFIRMATION_DATASET_START':
      return {
        ...state,
        loadingConfirmationDataset: true,
      };

    case 'UPLOAD_BULK_OPERATIONS_DATASET_START':
      return {
        ...state,
        loadingBulkOperationsDataset: true,
      };

    case 'UPLOAD_CONFIRMATION_DATASET_SUCCESS':
      return {
        ...state,
        loadingConfirmationDataset: false,
      };
    case 'UPLOAD_BULK_OPERATIONS_DATASET_SUCCESS':
      return {
        ...state,
        loadingBulkOperationsDataset: false,
      };

    case 'UPLOAD_CONFIRMATION_DATASET_FAIL':
      return {
        ...state,
        loadingConfirmationDataset: false,
      };

    case 'UPLOAD_BULK_OPERATIONS_DATASET_FAIL':
      return {
        ...state,
        loadingBulkOperationsDataset: false,
      };

    case 'UPLOAD_DATASET_START':
      return {
        ...state,
        loadingDataset: true,
      };

    case 'UPLOAD_DATASET_SUCCESS':
      return {
        ...state,
        loadingDataset: false,
      };

    case 'UPLOAD_DATASET_FAIL':
      return {
        ...state,
        loadingDataset: false,
      };

    case 'CREATE_LOG_START':
      return { ...state, loading: true };

    case 'CREATE_LOG_SUCCESS':
      return { ...state, loading: false };

    case 'CREATE_LOG_FAIL':
      return { ...state, loading: false };

    case 'GET_LOG_LIST_START':
      return { ...state, loadingLogList: true };

    case 'GET_LOG_LIST_SUCCESS':
      return {
        ...state,
        logList: action.payload,
        loadingLogList: false,
      };

    case 'GET_LOG_LIST_FAIL':
      return { ...state, loadingLogList: false };

    case 'GET_LOG_START':
      return { ...state, loadingLog: true };

    case 'GET_LOG_SUCCESS':
      return {
        ...state,
        log: action.payload,
        loadingLog: false,
      };

    case 'GET_LOG_FAIL':
      return { ...state, loadingLog: false };

    case 'ADMINISTRATION_ACTION_CHANGED':
      return { ...state, actionId: action.payload };

    case 'ADMINISTRATION_COMMENT_CHANGED':
      return { ...state, comment: action.payload };

    case 'TOGGLE_ADMINISTRATION_STARTDATE':
      const dialogStartDateOpen = !state.dialogStartDateOpen;
      return { ...state, dialogStartDateOpen };

    case 'TOGGLE_ADMINISTRATION_ENDDATE':
      const dialogEndDateOpen = !state.dialogEndDateOpen;
      return { ...state, dialogEndDateOpen };

    case 'TOGGLE_ADMINISTRATION_WARNING':
      const dialogWarningOpen = !state.dialogWarningOpen;
      return { ...state, dialogWarningOpen };

    case 'SET_ADMINISTRATION_STARTDATE':
      const startDate = action.payload;

      return { ...state, startDate };

    case 'SET_ADMINISTRATION_ENDDATE':
      const endDate = action.payload;

      return { ...state, endDate };

    case 'GET_ISSUER_ORGANIZATION_BALANCE_ACCOUNT_START':
      return { ...state, loadingBalance: true };

    case 'GET_ISSUER_ORGANIZATION_BALANCE_ACCOUNT_SUCCESS':
      return {
        ...state,
        loadingBalance: false,
        selectedOrganizationBalanceAccount: action.payload,
      };

    case 'GET_ISSUER_ORGANIZATION_BALANCE_ACCOUNT_FAIL':
      return { ...state, loadingBalance: false };

    case 'ADMINISTRATION_ACTION_START':
      return { ...state, loadingAdministrationAction: true };

    case 'ADMINISTRATION_ACTION_END':
      return { ...state, loadingAdministrationAction: false };

    case 'INCREASE_BALANCE_FORM_ERROR':
      return {
        ...state,
        increaseBalanceFormError: action.payload,
      };

    case 'DECREASE_BALANCE_FORM_ERROR':
      return {
        ...state,
        decreaseBalanceFormError: action.payload,
      };

    case 'ISSUE_CERTIFICATES_FORM_ERROR':
      return {
        ...state,
        issueCertificatesFormError: action.payload,
      };
    case 'ISSUE_CERTIFICATES_BULK_FORM_ERROR':
      return {
        ...state,
        issueCertificatesBulkFormError: action.payload,
      };
    case 'NOTIFICATION_OF_ISSUANCE_FORM_ERROR':
      return {
        ...state,
        notifyFutureIssuanceFormError: action.payload,
      };

    case 'GET_MAX_QUANTITY_FOR_PRODUCT_START':
      return { ...state, loadingMaxQuantityForProduct: true };
    case 'GET_MAX_QUANTITY_FOR_PRODUCT_SUCCESS':
      return {
        ...state,
        loadingMaxQuantityForProduct: false,
        maxQuantityForProduct: action.payload,
      };
    case 'GET_MAX_QUANTITY_FOR_PRODUCT_FAIL':
      return {
        ...state,
        loadingMaxQuantityForProduct: false,
        maxQuantityForProduct: null,
      };

    case 'CHECK_ATTRIBUTES_FORM_ERROR':
      return {
        ...state,
        loadingCheckAttributes: false,
        checkAttributesFormError: action.payload,
      };

    case 'GET_ORGANIZATIONS_SUCCESS':
      let organizations = action.payload;
      return { ...state, organizationId: organizations[0]._id };
    case 'GET_SCHEDULED_TASKS_MONITORING_SUCCESS':
      return { ...state, scheduledTasksMonitoring: action.payload };

    case 'RESET_DECREASE_BALANCE_FORM':
      return {
        ...state,
        resetDecreaseBalanceForm: true,
        displayDecreaseBalanceFormInDialog: true,
      };
    case 'RESET_INCREASE_BALANCE_FORM':
      return {
        ...state,
        resetIncreaseBalanceForm: true,
        displayIncreaseBalanceFormInDialog: true,
      };
    case 'RESET_ISSUANCE_EMAIL_NOTIFICATION_FORM':
      return {
        ...state,
        resetIssuanceNotificationEmailForm: true,
        displayNotificationIssueFormInDialog: true,
      };
    case 'RESET_CERTIFICATES_FORM':
      return {
        ...state,
        resetIssueCertificateForm: true,
        displayIssueCertificateFormInDialog: true,
      };
    case 'RESET_CERTIFICATES_IN_BULK_FORM':
      return {
        ...state,
        resetIssueCertificateInBulkForm: true,
        displayIssueCertificateBulkFormInDialog: true,
      };
    case 'RESET_RESET_CHECK_PRODUCT_ATTRIBUTES_FORM':
      return {
        ...state,
        resetCheckAttributesForm: true,
        displayCheckAttributesFormInDialog: true,
      };

    default:
      return state;
  }
};

export default administrationReducer;
