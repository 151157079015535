import { Fab, Tooltip, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import moment from 'moment';

import downloadIcon from '../../../../../assets/images/icons/download-from-cloud.png';
import OperationTableCell from '../CustomCell/OperationTableCell';
import OperationTableStatusCell from '../CustomCell/OperationTableStatusCell';

const downloadJson = (Data: any) => {
  const dataStr =
    'data:application/json;charset=utf-8,' +
    encodeURIComponent(JSON.stringify(Data));
  const download = document.createElement('a');
  download.setAttribute('href', dataStr);
  download.setAttribute('download', 'requestBody' + '.json');
  document.body.appendChild(download);
  download.click();
  download.remove();
};

const OperationsColumns = (): GridColDef[] => {
  const dataGridColumns: GridColDef[] = [
    {
      field: 'status',
      headerName: 'Status',
      editable: false,
      width: 60,
      filterable: true,
      sortable: true,
      valueGetter: (params) => {
        let isInError = false;
        if (
          params.row.statusCode > 299 ||
          params.row.status === 'error' ||
          params.row.success === false
        )
          isInError = true;
        return isInError ? 'Error' : 'Success';
      },
      renderCell: (params: any) => {
        console.log('params', params);
        return <OperationTableStatusCell status={params.value} />;
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      editable: false,
      width: 200,
      filterable: true,
      sortable: true,
      renderCell: (params: any) => (
        <OperationTableCell
          value={params.row?.type}
          statusCode={params.row?.statusCode}
          status={params.row?.status}
          success={params.row?.success}
        />
      ),
    },
    {
      field: 'dateTime',
      headerName: 'Date',
      editable: false,
      width: 200,
      type: 'date',
      valueFormatter: ({ value }) =>
        value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '',
      renderCell: (params) => (
        <div
          style={{
            color: params.row?.statusCode > 299 ? 'rgb(194, 14, 14)' : '',
            cursor: 'pointer',
            minWidth: 68,
            maxWidth: 68,
            width: 68,
          }}
        >
          {params.row.dateTime
            ? moment(params.row.dateTime).format('YYYY-MM-DD')
            : 'Unknown date'}
          <Typography variant="caption" style={{ color: '#CECECE' }}>
            {params.row.dateTime
              ? moment(params.row.dateTime).format('HH:mm:ss')
              : ''}
          </Typography>
        </div>
      ),
    },
    {
      field: 'path',
      headerName: 'Endpoint',
      editable: false,
      width: 400,
      filterable: true,
      sortable: true,
      renderCell: (params: any) => (
        <OperationTableCell
          value={params.row?.path}
          statusCode={params.row?.statusCode}
          status={params.row?.status}
          success={params.row?.success}
        />
      ),
    },
    {
      field: 'user',
      headerName: 'User',
      editable: false,
      width: 200,
      filterable: true,
      sortable: true,
      valueGetter: (params) => {
        return params.row?.user?.username;
      },
      renderCell: (params: any) => (
        <OperationTableCell
          value={params.row?.user?.username}
          statusCode={params.row?.statusCode}
          status={params.row?.status}
          success={params.row?.success}
        />
      ),
    },
    {
      field: 'body',
      headerName: 'Request body',
      editable: false,
      width: 200,
      filterable: false,
      sortable: false,
      disableExport: true,
      renderCell: (params) => (
        <div style={{ textAlign: 'center' }}>
          {params.row.requestBody && (
            <Tooltip
              title={'Download request body'}
              placement="bottom"
              enterDelay={100}
            >
              <Fab
                size="small"
                color="primary"
                aria-label="add"
                style={{
                  backgroundColor: '#FFFFFF',
                }}
                onClick={() => {
                  downloadJson(params.row.requestBody);
                }}
              >
                <img
                  src={downloadIcon}
                  alt={'Download request body ' + params.row.id}
                  title={'Download request body ' + params.row.id}
                  style={{ width: 22, height: 22 }}
                />
              </Fab>
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  return dataGridColumns;
};

export default OperationsColumns;
