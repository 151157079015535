import { useEffect } from 'react';

import { getOffchainProdSources, getProductStart } from 'actions';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { AppDispatch } from 'redux/store';

import useProductSlicesCombiner from '../Slices/useProductSlicesCombiner';

export const useProduct = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { assetId } = useParams();
  const { isAuthenticated } = useProductSlicesCombiner();

  useEffect(() => {
    const initComponent = async () => {
      if (!isAuthenticated) navigate('/');
      else {
        dispatch(getProductStart(assetId));
        dispatch(getOffchainProdSources('Production'));
      }
    };
    initComponent();
  }, []);
};
